.TimePeriodModal {
  .ant-modal-content {
    padding: 30px 20px;
  }
  &__container {
    width: 100%;
  }
  &__date {
    display: flex;
    margin-top: 16px;
    &--item {
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: 8px;
      }
      &-label {
        margin-right: 6px;
      }
    }
  }
  &__title {
    text-align: center !important;
    margin-bottom: 20px;
  }
  &__submit {
    margin-top: 20px;
  }
  &__errorMsg {
    margin-top: 8px;
  }
}
