@use 'src/themes/variables' as *;

.MessagesPage {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }

  .CommonContent {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      background: none;
      min-height: 0;
      
      @media (max-width: 768px) {
        padding: 0;
      }
    }

    @media (max-width: 768px) {
      margin: 0;
      padding: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 380px 1fr;
    gap: 24px;
    flex: 1;
    min-height: 0;
    background: transparent;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      height: 100vh;
      gap: 0;
      padding: 0;
      overflow: hidden;
      position: relative;
    }
  }

  &__left-side-bar,
  &__right-side-bar {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 24px;
    min-height: 0;

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      border-radius: 0;
      padding: 16px;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.3s ease;
    }

    &.hidden {
      @media (max-width: 768px) {
        transform: translateX(-100%);
      }
    }
  }

  &__right-side-bar {
    @media (max-width: 768px) {
      transform: translateX(100%);
      z-index: 2;

      &.hidden {
        transform: translateX(100%);
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    border-radius: 100px;
    margin: 3px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    border-radius: 100px;
  }
}

