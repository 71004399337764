@use 'src/themes/variables' as *;

.PractitionersPage {
  &__inviteBtn {
    &.ant-btn {
      &:hover {
        color: $white;
        background-color: $color-primary;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__error {
    text-align: center !important;
  }
}
