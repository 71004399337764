.dass-stat-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  border-left: 4px solid;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  
  .stat-title {
    margin-bottom: 16px;
  }

  .stat-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 16px;

    .stat-item {
      text-align: center;
      padding: 8px;
      background: #fafafa;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      .severity-badge {
        font-size: 11px;
        font-weight: 500;
        padding: 2px 8px;
        border-radius: 12px;
        color: white;
        margin-top: 4px;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }
  }

  .trend-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    border-top: 1px solid #f0f0f0;

    .trend-indicator {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .change-percent {
      color: #8c8c8c;
    }
  }
} 