@use 'src/themes/variables' as *;
.EmotionIcon {
  /* Apply a transparent border to all EmotionIcon elements */

}
.RecentScore {

  width: 100%;
  padding: 20px;
  padding-top: 16px;
  &__item {
    border: 1px solid $color-offline;
    margin-top: 16px;
    border-radius: 8px;
    padding: 20px;
  }
  &__title {
    color: $color-success;
    margin-bottom: 16px;
  }
  &__container {
    margin-top: 16px;
  }
  &__line {
    height: 200px;
    flex: 1;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__filter {
    padding: 10px;
    border-radius: 10px;
    background-color: $bg-input-color;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 140px;
    justify-content: space-between;
  }
  &__loading {
    height: 200px;
    text-align: center;
    padding-top: 80px;
  }
  &__trackedContainer {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__stackedRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__moodContainer {
    align-items: flex-start; /* Align items to the top of the container */
    margin: 10px;
    position: relative; /* Set the position property to relative */
  }
  &__badge {
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 20px;
    width: 20px;
    position: absolute; /* Set the position property to absolute */
    top: -5px; /* Move the badge 5px upwards */
    right: -5px; /* Move the badge 5px to the left */
  }
  &__badgeText {
    font-size: 12px;
  }
  &__feeling {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F6F7F9;
    border-radius: 8px;
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &__feelingItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
    /* Add this to your CSS */
  .RecentScore__selectedScores {
    list-style: none;
    padding-top: 20px;
    width: 50%; /* Reduce the width by 50% */
    margin: 0 auto; /* Center-align the container horizontally */

  }

  .SelectedScore__item {
    margin-bottom: 15px;
    
  }

  .SelectedScore__date {
    /* Add styles for the date, float it to the left */
    font-weight: bold;
    float: left;
  }
  
  .SelectedScore__time {
    /* Add styles for the time, float it to the right */
    font-weight: normal; /* Remove bold for time */
    float: right;
    color: #333; /* Dark gray for time */
  }
  
  .SelectedScore__comment {
    /* Add styles for the comment, displayed underneath */
    clear: both; /* Clear the float from time */
    margin-top: 5px; /* Add margin for spacing */
    padding-top: 10px;
    color: #333; /* Dark gray for comment */
  }
  
  .SelectedScore__item .light-grey-text {
    color: #aaa; /* Light grey color for comments with text "No comment available" */
  }

  /* Add this to your CSS */
  .selected-emotion-icon {
    /* Add your highlight styles here, e.g., a border or background color */
    color: #48ABE2;
    border: 2px solid;
    border-radius: 50%;
  }

}
