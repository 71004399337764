@use 'src/themes/variables' as *;

.ProfileGeneralInformation {
  padding-bottom: 16px;
  .ProfileGeneralInformationForm {
    border-bottom: 1px solid $border-default-color;

    &__row {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .FormItem {
        width: 100%;
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      .Button {
        width: 160px;
      }
    }
  }

  &__section {
    padding: 20px;

    &-personal-information {
      .ProfileGeneralInformation__section-title {
        margin-bottom: 16px;
      }
    }
  }

  &__upload-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $border-default-color;
    padding-bottom: 20px;
  }

  &__preview-avatar {
    width: 70px;
    height: 70px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bg-empty-color;
  }

  &__file-requirement {
    color: $font-color-caption;
  }

  &__my-account-email {
    margin: 10px 0 16px 0;
  }
}
