@use 'src/themes/variables' as *;

.InviteModal {
  .ant-modal-content {
    padding: 30px 20px;
    position: relative;

    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    @media (max-width: 768px) {
      padding: 20px 16px;
    }

    .invite-modal {
      width: 100%;
      text-align: center;

      .ant-row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-bottom: 16px;

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 0;
        }
      }

      &__button {
        width: 200px;
        height: 48px;
        align-items: center;
        display: flex;
        margin: auto;
        padding: 0 24px;

        @media (max-width: 768px) {
          width: 100%;
          margin-top: 8px;
        }
      }

      .left-input,
      .right-input {
        flex: 1;
        margin: 0;
        min-width: 0;

        @media (max-width: 768px) {
          width: 100%;
        }

        .ant-form-item-control-input {
          width: 100%;
        }

        .ant-input,
        .PhoneNumber {
          width: 100%;
        }
      }
    }
  }
}
