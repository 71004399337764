@use 'src/themes/variables' as *;
.InsightsTab {
  padding-left: 20px;
  padding-right: 20px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.TabsHeader {
  &.ant-tabs {
    // css only secondary tab header
    &.TabsHeader.secondary {
      &.ant-tabs {
        .ant-tabs {
          &-nav {
            padding: 0 10px;
            border-radius: 0;
            background-color: transparent;

            &::before {
              border-color: $border-default-color;
            }
          }

          &-tab {
            padding: 10px 0;
            line-height: 16px;
            font-size: $font-size-caption;
          }

          &-ink-bar {
            display: none; // Hide the ink bar
          }
        }
      }
    }
  }
}
