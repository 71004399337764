@use 'src/themes/variables' as *;

.HomeworkHistoryDetailsModal {
  &.ant-modal {
    .ant-modal {
      &-content {
        padding: 20px 20px 30px;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  &__action {
    width: 170px;
    margin: 16px auto 0;
  }

  &__video {
    display: flex;
    gap: 16px;
    background-color: transparent;
    padding: 0;
    margin: 8px 0 16px;

    &-info {
      flex: 1;
      background: $bg-gray;
      border-radius: 8px;
      padding: 16px 16px 30px;
      text-align: left;

      &-description {
        margin: 8px 0 10px 0;
      }

      &-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        svg {
          margin-right: 6px;
        }
      }

      &-hour {
        margin-left: 6px;
      }
    }
  }

  &__comment {
    margin-top: 16px;
  }

  &__result {
    margin-top: 16px;
  }

  &__info {
    background: $bg-gray;
    border-radius: 8px;
    padding: 16px 16px 30px;
    margin-bottom: 16px;

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  .conversation {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;

    &.user {
      text-align: left;
      background-color: #cfe2f3;
    }

    &.assistant {
      text-align: right;
      background-color: #d9ead3;
    }

    &.today {
      background-color: #e8f5e9; /* Light green background for today's messages */
    }
  }

  .role {
    font-weight: bold;
  }

  .content {
    margin-top: 5px;
  }

  .thumbs-container {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  .softDeleted {
    opacity: 0.5;
  }

  .date-separator {
    display: flex;
    align-items: center;
    margin: 10px 0;
    text-align: center;

    span {
      flex: 1;
      font-weight: bold;
      color: #666;
    }

    hr {
      flex: 2;
      border: none;
      border-top: 1px solid #ddd;
      margin: 0 10px;
    }
  }
  .conversation-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .summary-display {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    margin-bottom: 40px;
    margin-top: 20px;
    font-family: 'Courier New', Courier, monospace; /* Stylish font for a typewriter feel */
    font-size: 1.1em;
    line-height: 1.6;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    color: #333;
    white-space: pre-wrap; /* Preserve line breaks in summary */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for effect */
  }
  
}
