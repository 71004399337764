.Container__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      width: calc(100vw - 20px);
    }
}

.FlexibleCommonContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - --mobile-footer-height);

    &__header {
        margin-bottom: 0px;
    }
}

.FlexibleCommonContent__non-flex-content {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.header {
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  background: white;
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid #e8e8e8;

  .clientSelection {
    position: relative;
    width: 100%;

    .selectedClients {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 12px;

      @media (max-width: 768px) {
        margin-bottom: 0;
        flex-direction: row;
        align-items: center;
        position: relative;

        .ant-input {
          flex: 1;
          min-width: 0;
        }
      }
    }

    .search-row {
      display: flex;
      gap: 8px;
      width: 100%;
      position: relative;

      .ant-input {
        flex: 1;
        min-width: 0;
      }

      @media (max-width: 768px) {
        display: none;

        .select-active-btn {
          display: none;
        }
      }
    }
  }
}

.clientSelection {
  .searchResults {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1050;

    @media (max-width: 768px) {
      position: fixed;
      top: auto;
      left: 16px;
      right: 16px;
      width: auto;
      z-index: 1051;
    }

    .searchResultItem {
      padding: 8px 12px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.bulk-assign-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: #f5f5f5;
    box-sizing: border-box;

    .AssignHomework {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        gap: 16px;
        flex: 1;
        min-height: 0;

        &__topic,
        &__tasks,
        &__assigned {
            background: white;
            border: 1px solid #e8e8e8;
            border-radius: 8px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            min-height: 0;
            width: 100%;
            box-sizing: border-box;
        }

        &__assigned {
            &-list {
                flex: 1;
                overflow-y: auto;
                margin: 16px 0;
                min-height: 0;
            }

            &-actions {
                margin-top: auto;
                padding-top: 16px;
                border-top: 1px solid #e8e8e8;
                display: flex;
                gap: 12px;
                justify-content: flex-end;
            }
        }

        &__mobile-nav {
            position: sticky;
            top: 0;
            z-index: 10;
            background: white;
            padding: 16px;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100vw;

            .nav-left {
                display: flex;
                align-items: center;
            }

            .nav-right {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}

.HomeworkPage {
    @media (max-width: 768px) {
        .bulk-assign-container {
            background: white;

            .header {
                display: none;
            }

            .AssignHomework {
                display: block;
                padding: 0;
                background: white;
                height: calc(100vh - var(--mobile-footer-height, 80px));
                //overflow: hidden;

                &__mobile-nav {
                    position: sticky;
                    top: 0;
                    z-index: 10;
                    background: white;
                    padding: 16px;
                    border-bottom: 1px solid #e8e8e8;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: calc(100vh - 118px);

                    .nav-left {
                        display: flex;
                        align-items: center;
                    }

                    .nav-right {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }

                &__topic,
                &__tasks,
                &__assigned {
                    height: 100vw;
                    margin-bottom: 0;
                    border: none;
                    border-radius: 0;
                    display: none;
                    background: white;
                    box-sizing: border-box;
                    position: relative;

                    &.active {
                        display: flex;
                        flex-direction: column;
                    }
                }

                &__assigned {
                    &.active {
                        padding: 16px;
                        height: calc(100vh - 148px);
                        overflow-y: auto;

                        .clientSelection {
                            margin-bottom: 16px;
                        }

                        .selectedClients {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 8px;
                            
                            @media (max-width: 768px) {
                                margin-bottom: 0;
                            }
                        }

                        .AssignHomework__assigned-list {
                            flex: 1;
                            min-height: 0;
                            margin: 16px 0;
                        }

                        .AssignHomework__assigned-actions {
                            position: fixed;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            padding: 16px;
                            background: white;
                            box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
                            display: flex;
                            gap: 12px;
                            justify-content: space-between;
                            z-index: 10;
                        }
                    }
                }
            }

            &__mobile-footer {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 16px;
                background: white;
                box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
                z-index: 100;

                .ant-btn {
                    height: 48px;
                    font-size: 16px;
                }
            }

            //padding-bottom: 80px;
        }
    }
}
