.ConversationList {
  overflow: auto;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  &__loading {
    display: flex;
    justify-content: center;

    &.custom {
      margin-top: -8px;
    }
  }

  &__searching {
    margin-top: 20px;
  }

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: visible !important;
  }

  @media (max-width: 768px) {
    height: 100%;
    
    .infinite-scroll-component {
      height: 100%;
    }
  }
}

.Conversations {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}
