@use 'src/themes/variables' as *;

.PolaChart {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;
  width: 469.5px;

  &__chart {
    width: 218.75px;
    height: 201px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    width: 218.75px;
    // height: 201px;

    &-head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;
      width: 218.75px;
      height: 65px;

      &-total {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;
        width: 218.75px;
        height: 40px;

        .total-number {
          color: $color-success;
        }
      }
    }

    &-note {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 6px;
      width: 218.75px;

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 6px;
        width: 218.75px;
        height: 16px;

        .icon > circle {
          fill: inherit;
        }
      }
    }
  }
}
