.NotificationBox {
  padding-right: 8px;
  max-height: 490px;
  width: 410px;
  margin-right: -8px;
  overflow: auto;

  &__loading {
    display: flex;
    justify-content: center;
  }

  &__today {
    margin-left: 16px;
    font-weight: 500;
  }

  &__earlier {
    margin-left: 16px;
    &--margin-top {
      margin-top: 24px;
    }
  }
}

.clear-button {
  float: right; /* Align the button to the right */
  background: none; /* Remove background color */
  border: none; /* Remove border */
  color: #48abe2; /* Apply the desired light blue text color */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: color 0.3s; /* Add a smooth transition for color change */
}

.clear-button:hover {
  color: #1890ff; /* Change the color to match Ant Design's primary color on hover */
}
