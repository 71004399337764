@use 'src/themes/variables' as *;

.ClientDetailsPage {
  &__loading {
    display: block;
  }

  &__wrapper {
    .CommonContent__content {
      padding: 0;
      
      // If using ant-design tabs, you might want to customize them
      .ant-tabs {
        height: 100%;
      }

      .ant-tabs-content-holder {
        overflow: auto;
      }
    }
  }

  &__title {
    .StatusBox__status {
      line-height: 15px;
    }
  }

  &__confirmModal {
    &-description {
      text-align: center !important;
      color: $font-color-caption;
      white-space: pre-line;
    }

    &-reactivateIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      background-color: #e6fbf3;
    }
  }

  &__ConfirmUnsaveChangesModal {
    &-text {
      color: $font-color-caption;
    }
  }

  &__content-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    width: 100%;
    padding: 24px;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__tabs-container {
    grid-column: 1 / -1; // Span full width
    width: 100%;
  }

  &__info-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    @media (max-width: 1023px) {
      grid-column: 1 / -1;
    }
  }

  &__tab-content {
    width: 100%;
    height: 100%;
  }

  &__general-info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    max-width: 1600px;
    margin: 0 auto;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__info-card {
    background: $white;
    border-radius: 8px;
    height: 100%;
    
    // If your cards need a subtle shadow or border
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    // Override flex display for form rows within info cards
    .ClientGeneralInfomationForm__row {
      display: block;
    }
  }
}
