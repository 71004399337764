.AITab {
  padding: 24px;

  &__tabs {
    .ant-tabs-nav {
      margin-bottom: 0 !important;
      background: #fff;
      border-radius: 8px 8px 0 0;
      border: 1px solid #f0f0f0;
      border-bottom: none;
      padding: 0 8px;
    }

    .ant-tabs-content {
      padding: 24px;
      background: #fff;
      border-radius: 0 0 8px 8px;
      border: 1px solid #f0f0f0;
      border-top: none;
    }

    .ant-tabs-tab {
      padding: 12px 16px;
      
      .anticon {
        margin-right: 8px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  }

  &__headerLeft {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__headerRight {
    display: flex;
    align-items: center;
    gap: 16px;
    height: fit-content;
    margin-top: auto;
  }

  &__datePicker {
    min-width: 300px;
  }

  &__actionButton {
    height: 32px;
    padding: 4px 15px;
    
    .anticon {
      font-size: 14px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__checkboxWrapper {
    margin-right: 8px;

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.65);
      
      &:hover {
        color: #1890ff;
      }
    }
  }

  &__table {
    margin-top: 16px;
    
    .ant-table-thead > tr > th {
      background-color: #f5f5f5;
      font-weight: 600;
    }
  
    .ant-table-row {
      cursor: pointer;
      
      &:hover {
        background-color: #f0f7ff !important;
      }
    }
  }

  &__summaryList {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 0;
  }

  &__summaryCard {
    border-radius: 8px;
  }

  &__summaryCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__summaryCardContent {
    max-height: 400px;
    overflow-y: auto;
    padding: 16px;
    background: #fafafa;
    border-radius: 8px;
  }

  &__modal {
    .ant-modal-body {
      max-height: 70vh;
      overflow-y: auto;
      padding: 24px;
    }
  }

  &__chatSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 400px;
  }

  &__chatMetadata {
    padding: 8px 16px;
    background: #fafafa;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
  }

  &__chatbotBox {
    &--scrollable {
      flex: 1;
      overflow-y: auto;
      padding: 16px;
      background: #fff;
      border: 1px solid #f0f0f0;
      border-radius: 8px;
    }
  }

  &__chatMessage {
    margin-bottom: 16px;
    max-width: 80%;

    &--user {
      margin-left: auto;
      .AITab__chatMessageContent {
        background: #e6f7ff;
        border-radius: 12px 12px 0 12px;
      }
    }

    &--assistant {
      margin-right: auto;
      .AITab__chatMessageContent {
        background: #f6ffed;
        border-radius: 12px 12px 12px 0;
      }
    }
  }

  &__chatMessageHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 12px;
    padding: 0 4px;
  }

  &__chatMessageContent {
    padding: 12px 16px;
    white-space: pre-wrap;
    
    p {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__chatMessageCode {
    background: rgba(0, 0, 0, 0.04);
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', monospace;
  }

  &__chatMessagePre {
    background: rgba(0, 0, 0, 0.04);
    padding: 12px;
    border-radius: 4px;
    overflow-x: auto;
    margin: 8px 0;
    font-family: 'Courier New', monospace;

    code {
      background: none;
      padding: 0;
    }
  }

  &__loadingState {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }

  &__chatActions {
    padding: 16px;
    border-top: 1px solid #e8e8e8;
    display: flex;
    justify-content: flex-end;
  }

  &__conversationView {
    flex: 1;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
  }

  &__conversationViewHeader {
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
  }

  &__headerActions {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    padding: 16px;

    &__grid {
      grid-template-columns: 1fr;
    }
  }

  // Remove the grid and card styles since we're not using them anymore
  &__grid,
  &__card,
  &__cardHeader,
  &__cardTitle,
  &__cardActions {
    display: none;
  }

  &__summaryView {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__summaryMetadata {
    background: #fafafa;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__analyzedConversations {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__conversationTags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    
    .ant-tag {
      margin: 0;
      padding: 4px 8px;
      font-size: 12px;
    }
  }

  &__summaryConversations {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    
    .ant-tag {
      margin: 0;
      padding: 4px 8px;
    }
  }

  &__modalFooter {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: 1px solid #f0f0f0;
    margin: 0 -24px -24px;
  }

  &__summaryContent {
    max-height: 400px;
    overflow-y: auto;
    padding: 16px;
    background: #fafafa;
    border-radius: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    color: #2c3e50;

    h1 {
      font-size: 1.5em;
      margin: 1em 0 0.6em;
      color: #1a1a1a;
      font-weight: 600;
      border-bottom: 1px solid #eaecef;
      padding-bottom: 0.3em;
    }

    h2 {
      font-size: 1.3em;
      margin: 1em 0 0.6em;
      color: #2c3e50;
      font-weight: 600;
    }

    h3 {
      font-size: 1.1em;
      margin: 1em 0 0.6em;
      color: #34495e;
      font-weight: 600;
    }

    p {
      margin: 0.8em 0;
      line-height: 1.6;
    }

    ul, ol {
      margin: 0.5em 0;
      padding-left: 1.5em;

      li {
        margin: 0.3em 0;
        line-height: 1.6;
      }
    }

    blockquote {
      margin: 1em 0;
      padding: 0.5em 1em;
      color: #6a737d;
      border-left: 0.25em solid #dfe2e5;
      background: #fff;
      
      p {
        margin: 0.5em 0;
      }
    }

    code {
      font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
      font-size: 0.9em;
      background: rgba(27, 31, 35, 0.05);
      padding: 0.2em 0.4em;
      border-radius: 3px;
    }

    pre {
      background: #f6f8fa;
      padding: 1em;
      border-radius: 4px;
      overflow-x: auto;
      font-size: 0.9em;
      
      code {
        background: none;
        padding: 0;
      }
    }

    strong {
      font-weight: 600;
      color: #24292e;
    }

    em {
      font-style: italic;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin: 1em 0;
      
      th, td {
        border: 1px solid #dfe2e5;
        padding: 0.6em 1em;
        text-align: left;
      }
      
      th {
        background: #f6f8fa;
        font-weight: 600;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__checkboxWrapper {
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.65);
      
      &:hover {
        color: #1890ff;
      }
    }
  }

  &__viewButton.ant-btn-link {
    padding: 0;
    color: #1890ff;

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  &__introSection {
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }
    
    ul {
      margin-left: 20px;
      margin-top: 8px;
    }
    
    li {
      margin-bottom: 4px;
    }
  }
} 