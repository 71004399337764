@use 'src/themes/variables' as *;

.ImgContainer {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  &__left {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;

    @media (max-width: 768px) {
      display: none;
    }

    &-container {
      max-width: 480px;
      margin: 0 auto;
    }

    &-logo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      margin-bottom: 2rem;

      .BaseText {
        color: white;
      }
    }

    &-title, .BaseText {
      color: white;
    }
  }

  &__right {
    background-color: white;

    @media (max-width: 768px) {
      min-height: 100vh;
    }
  }
}
