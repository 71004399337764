@use 'src/themes/variables' as *;

.Statistics {
  width: 100%;
  padding: 20px;
  &__header {
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .ant-card {
      border: 1px solid #e8e8e8;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      height: 180px; /* Adjust the height as needed */
    }
    .badge {
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 20px;
      width: 20px;
      position: absolute; /* Set the position property to absolute */
      top: -5px; /* Move the badge 5px upwards */
      right: -5px; /* Move the badge 5px to the left */
    }
    .badge-text {
      font-size: 12px;
    }
    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%; /* Fill the entire card height */

      .metric-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .emoji-container {
        align-items: flex-start; /* Align items to the top of the container */
        margin: 10px;
        position: relative; /* Set the position property to relative */
      }

      .value-container {
        display: flex;
        flex-direction: column; /* Adjust to align content vertically */
        align-items: center;
        justify-content: center;

        .value {
          font-size: 24px;
          font-weight: bold;
          margin-top: 10px; /* Adjust spacing between title and value */
          margin-bottom: 5px;
        }

        .unit {
          font-size: 14px;
          color: #888;
        }

      }

      .loading-text {
        font-size: 16px;
        color: #aaa;
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-card {
    margin: 8px 0;
    
    .metric-title {
      font-size: 14px;
    }
    
    .value {
      font-size: 18px;
    }
    
    .emoji-container {
      flex-direction: column;
      
      .badge {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}

@media (max-width: 480px) {
  .custom-card {
    .emotion-icon {
      width: 48px;
      height: 48px;
    }
    
    .badge {
      height: 20px !important;
      width: 20px !important;
      
      &-text {
        font-size: 12px;
      }
    }
  }
}
