@use 'src/themes/variables' as *;

.ForgotPasswordPage {
  background-color: #f5f5f5;

  .ImgContainer__right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }

  &__container-heading {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__container-description {
    margin-bottom: 1rem;
    text-align: center;
  }

  &__container-timer {
    margin-bottom: 1rem;
    text-align: center;
    color: #ff4d4f;  // or any color you prefer for the timer
  }

  &__container-endText {
    text-align: center;
    margin-top: 1rem;

    .ant-typography {
      font-size: 12px;
    }
  }

  .ant-form-item {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__more-info-text {
    color: $font-color-caption;
    margin-bottom: 16px;
  }

  &__heading,
  &__endText {
    text-align: center !important;
    font-size: 12px;
  }

  &__endText {
    margin-top: 16px;
  }

  &__email {
    &.sent {
      display: none;
    }
  }

  &__errorMsg {
    margin-bottom: 16px;
  }
}
