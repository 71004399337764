@use 'src/themes/variables' as *;

.Sidebar {
  z-index: 999;
  overflow: auto;
  overflow-x: hidden;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 16px;
  border-right: 1px solid $bg-input-color;
  transition: all 0.3s ease;
  background: white;

  &.ant-layout-sider {
    position: fixed;
    top: 0;
  }

  &__mobile-toggle {
    display: none;
    position: fixed;
    top: 12px;
    left: 16px;
    z-index: 1000;
    background: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.3s ease;

    &.active {
      left: 286px;
    }

    .anticon {
      font-size: 20px;
      color: $font-color-default;
    }

    // Hide toggle button when header is hidden
    .Header.hide-mobile ~ .ant-layout & {
      display: none;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
  }

  &.mobile {
    position: fixed;
    transform: translateX(-100%);
    margin-top: 0;
    top: 0;
    height: 100vh;
    
    &.expanded {
      transform: translateX(0);
    }

    &.ant-layout-sider {
      margin-top: 0;
    }

    .Sidebar__logo {
      margin-top: 64px;
    }
  }

  &.ant-layout-sider-collapsed {
    .Sidebar__menu {
      text-align: center;

      .SidebarItem {
        justify-content: center;
        width: 48px;
        margin: auto;

        &.active {
          border-radius: 50%;
        }
      }

      .SidebarItem__label {
        display: none;
      }
    }
  }

  &__logo {
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  &__menu {
    margin-top: 16px;

    > a {
      display: block;
    }
  }

  a {
    color: $font-color-default;
    text-decoration: none;
  }

  .SidebarItem {
    display: flex;
    height: 48px;
    padding: 0 16px;
    align-items: center;
    border-radius: 10px;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba($bg-gray, 0.5);
    }

    .active-icon {
      display: none;
    }

    &.active {
      background-color: $bg-gray;

      .active-icon {
        display: inline-block;
      }

      .icon {
        display: none;
      }

      .SidebarItem__label {
        color: $color-primary;
      }
    }

    &__label {
      margin-left: 16px;
      transition: opacity 0.3s ease;
    }
  }
}

.ant-layout {
  &.has-sidebar {
    .ant-layout-content {
      margin-top: 64px;
      padding-left: 96px;
      transition: padding-left 0.3s ease;
      min-height: calc(100vh - 64px);
    }

    &.sidebar-expanded .ant-layout-content {
      padding-left: 340px;
    }
  }
}

@media (max-width: 768px) {
  .ant-layout {
    &.has-sidebar {
      .ant-layout-content {
        padding-left: 0 !important;

        // When header is hidden, remove top margin and adjust padding
        .Header.hide-mobile + & {
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }

  .Sidebar__mobile-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
