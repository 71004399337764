@use 'src/themes/variables' as *;

.CommonContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow: hidden;

  &__header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    &-title {
      display: flex;
      gap: 16px;
      align-items: center;
      flex: 1;
      min-width: 0;

      .BaseText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-action {
      flex-shrink: 0;
    }

    &-backBtn {
      flex-shrink: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__content {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $white;
    overflow: hidden;
  }
}
