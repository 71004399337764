@use 'src/themes/variables' as *;

.HomeworkVideoRow {
  display: inline-block;
  width: 177px;
  padding: 10px 10px 20px 10px;
  border: 1px solid $border-default-color;
  border-radius: 8px;
  margin: 0 6px 6px 0;
  cursor: pointer;

  &__thumbnail {
    display: block;
    border-radius: 8px;
    height: 110px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 10px;
  }

  &__content {
    &-title {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;
    }

    &-description {
      color: $font-color-caption;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 4px;
    }
  }

  &__icons {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
  }
}
