.AIStatusBox__container {
    display: flex;
    min-width: 59px;
    width: fit-content;
    height: 21px;
    border-radius: 6px;
    padding: 3px 8px;
    align-items: center;
  
    .AIStatusBox__status {
      font-family: 'Poppins-400';
      font-weight: 400;
      font-size: 10px;
      height: 15px;
    }
  }
  