@use 'src/themes/variables' as *;

.HomeworkTasksColumn {
  &__search {
    margin: 16px 0;
  }

  &__note {
    color: $font-color-caption;
  }

  &__list {
    height: 447px;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid $border-default-color;
    overflow-y: auto;

    &.all {
      height: 563px;
      margin-top: 16px;
    }
  }

  &__item {
    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  &__collapse {
    margin-top: 16px;
    &.Collapse.ant-collapse {
      .ant-collapse {
        &-header {
          padding: 12px;
          border-radius: 10px !important;
          color: $color-primary;
          border: 1px solid $border-active-color;
        }

        &-content-box {
          padding: 4px 0 0 0;
        }

        &-item {
          &:not(:first-child) {
            margin-top: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 16px;
    background: white;

    &__search {
      margin: 16px 0;
    }

    &__mobile-list {
      margin-top: 16px;
      border-top: 1px solid #e8e8e8;
    }

    &__item {
      &.mobile {
        padding: 16px;
        border-bottom: 1px solid #e8e8e8;
        background: white;

        &:last-child {
          border-bottom: none;
        }

        .HomeworkTasksColumn__item-content {
          display: flex;
          align-items: center;

          .HomeworkTasksColumn__item-left {
            display: flex;
            align-items: center;
            gap: 12px;
            flex: 1;

            .ant-checkbox-wrapper {
              flex-shrink: 0;
            }

            .BaseText {
              flex: 1;
            }
          }
        }
      }
    }

    // Hide drag handle on mobile
    .ant-drag-handle {
      display: none;
    }

    .ant-collapse {
      border: none;
      background: transparent;

      &-item {
        border: none;
      }

      &-header {
        padding: 12px 0;
      }

      &-content {
        border-top: none;
      }
    }
  }
}
