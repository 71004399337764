@use 'src/themes/variables' as *;

.DetailedStatistics {
  display: flex;

  &__container {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-card {
      background-color: $bg-gray;
      height: 112px;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    & {
      border-right: 1px solid $border-default-color;
    }
  }

  .decor {
    width: 4px;
    height: 20px;
    border-radius: 10px;

    &.discharged {
      background-color: $color-error;
    }
    &.active {
      background-color: $border-active-color;
    }
    &.pending {
      background-color: $activity-color;
    }

    &.message {
      background-color: $message-color;
    }
  }

  .total-color__discharged {
    color: $color-error;
  }
  .total-color__active {
    color: $border-active-color;
  }
  .total-color__pending {
    color: $activity-color;
  }
  .total-color__message {
    color: $message-color;
  }
}
