@use 'src/themes/variables' as *;

.Upload {
  .ant-btn {
    color: $border-active-color;
    border-color: $border-active-color;
    display: flex;
    align-items: center;
    border-width: 2px;

    .anticon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
