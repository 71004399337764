@use 'src/themes/variables' as *;

.Notifications {
  cursor: pointer;

  &.ant-badge {
    .ant-badge-count {
      &.ant-scroll-number {
        height: 16px;
        line-height: 16px;
        min-width: 16px;
        font-size: 10px;
      }
    }
  }

  &__Popover {
    &.ant-popover {
      @media (max-width: 576px) {
        position: fixed !important;
        top: 64px !important;
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
        transform: none !important;
        
        .ant-popover-arrow {
          display: none;
        }
      }

      .ant-popover-inner {
        border-radius: 8px;
        padding: 16px 8px;
        box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
        
        @media (max-width: 576px) {
          border-radius: 0;
          max-height: calc(100vh - 64px);
          overflow-y: auto;
        }
      }

      .ant-popover-content {
        @media (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }
}
