.ChangePasswordModal {
  &__title {
    margin-bottom: 16px;
  }

  &__email {
    margin: 16px 0;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    .Button {
      width: 100% !important;
    }
  }
}
