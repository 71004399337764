@use 'src/themes/variables' as *;

.ChatContent {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $font-color-caption;
  }

  &__list {
    flex: 1;
    min-height: 0;
    overflow: auto;
    display: flex;
    gap: 4px;
    flex-direction: column-reverse;
    padding-right: 20px;
  }

  &__loadMore {
    margin: 0 auto;
  }
}
