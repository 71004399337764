@use 'src/themes/variables' as *;

.NewFolderModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .ant-form-item-explain-error {
    margin-bottom: 16px;
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  &__footer {
    display: flex;
    gap: 10px;

    .button {
      width: 100%;
    }
  }

  &__title {
    font-family: 'Poppins-500';
    text-align: center;
    margin-bottom: 16px;
    font-size: $font-size-title;
  }
}
