@use 'src/themes/variables' as *;

.PhoneNumber {
  padding: 13px 10px;
  border-radius: 10px;
  font-size: $font-size-small;
  line-height: 20px;
  background-color: $bg-input-color;
  border-color: $bg-input-color;

  &:focus {
    background-color: $white;
    border-color: $border-active-color;
  }
}

.ant-form-item-has-error {
  .PhoneNumber {
    border-color: $color-error;
  }
}

.VerifiablePhoneInput {
  width: 100%;
  
  .phone-input-container {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: center;

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 8px;
    }
  }

  .phone-input {
    flex: 1;
    min-width: 280px;
    display: flex;
    align-items: center;
    gap: 8px;

    .PhoneInputCountry {
      flex: 0 0 auto;
      padding: 8px;
      background-color: $bg-input-color;
      border-radius: 8px;
      min-width: 80px;
      
      select {
        background-color: transparent;
        border: none;
        font-size: $font-size-small;
        color: rgba(0, 0, 0, 0.85);
        padding: 4px;
        cursor: pointer;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }

    .PhoneInputInput {
      flex: 1;
      min-width: 180px;
      height: 42px;
      padding: 8px 12px;
      border: 1px solid $bg-input-color;
      border-radius: 8px;
      font-size: $font-size-small;
      background-color: $bg-input-color;
      color: rgba(0, 0, 0, 0.85);
      transition: all 0.2s ease;

      &:hover {
        border-color: $border-active-color;
      }

      &:focus {
        background-color: $white;
        border-color: $border-active-color;
        box-shadow: 0 0 0 2px rgba($color-primary, 0.1);
        outline: none;
      }
    }
  }

  .verify-button {
    flex: 0 0 auto;
    height: 42px;
    min-width: 100px;
    padding: 0 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    font-size: $font-size-small;
    
    @media (max-width: 480px) {
      width: 100%;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.verified {
      color: $color-success;
      background: rgba($color-success, 0.1);
      border-color: transparent;

      &:hover {
        background: rgba($color-success, 0.15);
      }

      .verified-icon {
        color: $color-success;
      }
    }
  }

  .verified-icon {
    font-size: 20px;
    margin-left: 8px;
  }
}

.verification-modal {
  .ant-modal-content {
    border-radius: 12px;
    padding: 24px;
  }

  .ant-modal-header {
    margin-bottom: 24px;
    padding: 0;

    .ant-modal-title {
      font-size: $font-size-large;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .verification-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
    padding: 16px 0;

    .phone-number-display {
      font-size: $font-size-medium;
      font-weight: 500;
      color: $color-primary;
      word-break: break-all;
    }

    .verification-input {
      width: 100%;
      max-width: 220px;
      height: 42px;
      padding: 8px 12px;
      font-size: $font-size-medium;
      text-align: center;
      letter-spacing: 4px;
      border: 1px solid $bg-input-color;
      border-radius: 8px;
      background-color: $bg-input-color;
      transition: all 0.2s ease;

      &:hover {
        border-color: $border-active-color;
      }

      &:focus {
        background-color: $white;
        border-color: $border-active-color;
        box-shadow: 0 0 0 2px rgba($color-primary, 0.1);
        outline: none;
      }
    }

    .timer-text {
      color: rgba(0, 0, 0, 0.5);
      font-size: $font-size-small;
    }
  }

  .ant-modal-footer {
    margin-top: 8px;
    padding: 0;
    border-top: none;
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
    }

    button {
      height: 42px;
      min-width: 90px;
      border-radius: 8px;
      font-size: $font-size-small;

      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
}

// Error state
.ant-form-item-has-error {
  .PhoneInputInput {
    border-color: $color-error !important;
    
    &:focus {
      box-shadow: 0 0 0 2px rgba($color-error, 0.1);
    }
  }
}
