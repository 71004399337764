@use 'src/themes/variables' as *;

.ClinicInformation {
  padding: 20px;

  &__section-title {
    font-family: 'Poppins-500';
  }

  .ClinicInformationForm {
    margin-top: 16px;
    margin-bottom: 16px;

    &__row {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .FormItem {
        width: 100%;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      .Button {
        width: 160px;
      }
    }
  }
}
