@use 'src/themes/variables' as *;

.PsychoeducationPage {
  @media (max-width: 768px) {
    &.Container {
      margin: 0;
      padding: 0;
    }

    .Container__content {
      width: 100vw;
    }

    .CommonContent__header {
      padding: 0;
      display: none;  // Hide the header completely in mobile view
    }
  }

  .CommonContent__content {
    height: 788px;
    background-color: #f5f5f5;
  }

  &__grid {
    display: grid;
    grid-template-columns: 230px 1fr;
    gap: 20px;
    height: 100%;

    @media (max-width: 768px) {
      display: block;
      padding: 0;
    }
  }

  &__topics,
  &__lessons {
    background-color: white;
    border-radius: 8px;
    border: 1px solid $border-default-color;

    @media (max-width: 768px) {
      display: none;
      border-radius: 0;
      height: calc(100vh - var(--mobile-footer-height, 0px));
      
      &.active {
        display: block;
        height: calc(100vh - 64px);
      }
    }
  }

  &__topics {
    padding: 0;

    .PsychoeducationTopicList {
      height: 100%;
      overflow-y: auto;
      padding: 16px;
    }
  }

  &__lessons {
    padding: 20px;

    @media (max-width: 768px) {
      padding: 0;
    }

    &-details {
      max-height: 708px;
      overflow: auto;

      &-video {
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
          width: 100vw;
          
          > div {  // ReactPlayer container
            width: 100% !important;  // Override inline styles
            height: auto !important;
            aspect-ratio: 16/9;  // Maintain video aspect ratio
            
            iframe, video {  // Video element
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }

      div {
        img {
          @media (max-width: 768px) {
            width: 100% !important;  // Override any inline width
            height: auto;
          }
          width: 749px;
        }
        // ... rest of existing styles ...
      }
    }

    &-header {
      @media (max-width: 768px) {
        // Hide the header completely when we're showing the mobile nav
        // but keep it visible when showing lesson details
        display: none;

        .PsychoeducationPage__lessons-details & {
          display: flex;
        }
      }

      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 16px;

      &-icon {
        cursor: pointer;
      }
    }
  }

  &__mobile-nav {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid $border-default-color;
      background: white;
      position: sticky;
      top: 0;
      z-index: 10;

      .nav-left {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  &__empty,
  &__loading {
    grid-column: 1 / -1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 8px;
    border: 1px solid $border-default-color;

    @media (max-width: 768px) {
      border-radius: 0;
      border: none;
    }
  }
}
