@use 'src/themes/variables' as *;

.HomeworkPage {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    background: white;
  }

  .CommonContent {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    @media (max-width: 768px) {
      padding: 0;
      overflow: hidden;

      &__header {
        display: none;
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      background: #f5f5f5;
      min-height: 0;
      
      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }
  
  .CommonContent__non-flex-content {
    height: 100%;
    padding: 0;
    background: transparent;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  :global {
    .ant-layout-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

  }

  &__grid {
    display: grid;
    grid-template-columns: 380px 1fr;
    gap: 24px;
    flex: 1;
    min-height: 0;
    background: transparent;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      height: 100vh;
      gap: 0;
      padding: 0;
      overflow: hidden;
      position: relative;
    }
  }

  &__left-side-bar,
  &__right-side-bar {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 24px;
    min-height: 0;
    overflow-y: auto;

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      border-radius: 0;
      padding: 16px;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.3s ease;
    }
  }

  &__left-side-bar {
    @media (max-width: 768px) {
      transform: translateX(0);
      z-index: 1;

      &--hidden {
        transform: translateX(-100%);
      }
    }
  }

  &__right-side-bar {
    @media (max-width: 768px) {
      transform: translateX(100%);
      z-index: 2;
      background: $white;

      &--visible {
        transform: translateX(0);
      }
    }
  }

  &__back-button {
    display: none;
  }

  &__modal {
    @media screen and (max-width: 768px) {
      :global {
        .ant-modal {
          margin: 0;
          padding: 0;
          max-width: 100vw;
          width: 100vw !important;
          top: 0;
        }

        .ant-modal-content {
          height: 100vh;
          border-radius: 0;
        }

        .ant-modal-body {
          height: calc(100vh - 55px);
          padding: 16px;
          overflow-y: auto;
        }
      }
    }
  }

  &__tabs-header {
    border-top: 1px solid $border-default-color;
    border-bottom: 1px solid $border-default-color;
    margin-bottom: 24px;
    padding: 0;

    .ant-tabs-nav {
      background-color: $white !important;
      &::before {
        border: none;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  &__list {
    flex: 1;
    overflow-y: auto;
    min-height: 0;

    .ant-spin-container {
      height: 100%;
    }
  }

  &__loading,
  &__emptyHomework {
    width: 100%;
    padding-top: 100px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    border-radius: 100px;
    margin: 3px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    border-radius: 100px;
  }

  &__mobile-nav {
    display: none;
    
    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background: white;
      border-bottom: 1px solid #e8e8e8;
      position: sticky;
      top: 0;
      z-index: 10;
      width: 100%;

      .nav-left {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .nav-right {
        display: flex;
        align-items: center;
        gap: 8px;

        .ant-btn {
          height: 40px;
        }
      }
    }
  }

  &__search-bar {
    margin-bottom: 10px;
  }
}
