// Light Green - Primary Color Pallete
$light-green00: #62950b !default;
$light-green01: #85c021 !default;
$light-green02: #93d721 !default;
$light-green03: #b1ef48 !default;
$light-green04: #cbff72 !default;
$light-green05: #e8ffc1 !default;
$light-green06: #f4ffe2 !default;
$light-green07: #09ce82 !default;

// Grayscale - Neutral Color Pallete
$black: #000000 !default;
$grayscale90: #242424 !default;
$grayscale80: #424242 !default;
$grayscale70: #636363 !default;
$grayscale60: #808080 !default;
$grayscale50: #a0a0a0 !default;
$grayscale40: #bebebe !default;
$grayscale40: #d3d3d3 !default;
$grayscale30: #d3d3d3 !default;
$grayscale20: #ececec !default;
$grayscale10: #f5f5f5 !default;
$philippine-gray: #8a8a8a !default;
$white: #ffffff !default;

// Dark Green - Secondary Color Pallete
$dark-green01: #006832 !default;
$dark-green02: #148148 !default;
$dark-green03: #46ae78 !default;
$dark-green04: #6fd6a0 !default;
$dark-green05: #8ceebb !default;
$dark-green06: #b3fcd6 !default;
$dark-green07: #e0ffef !default;

// Semantic Color
$color-information: #1890ff !default;
$color-success: #35d6af !default;
$color-warning: #faad14 !default;
$color-error: #ff7777 !default;
$color-primary: #48abe2;
$bg-success: #35d6af !default;
$bg-error: #ff7777 !default;
$bg-gray: #eff2fc !default;
$bg-gray-2: #fcfcfc;
$bg-gray-3: #f6f7f9;

// Font color
$font-color-default: #292d32;
$font-color-caption: #8a8a8a;

// Border color
$border-default-color: #e1e6ef;

$dark-violet: #a25aff;
$light-violet: #f5eeff;
$grey: #f4f4f4;
$color-offline: #e1e6ef;
$border-active-color: #19bcfe;

// Bg color
$bg-input-color: #f4f4f4;
$bg-empty-color: #e1e6ef;
$bg-info-color: #fff5e5;
$bg-light-blue-color: #b1cbe4;
$bg-transparent-black-3: rgba(0, 0, 0, 0.3);
$bg-transparent-black-8: rgba(0, 0, 0, 0.8);

// homework color
$activity-color: #ffac30;
$question-color: $dark-violet;
$written-color: $color-success;
$video-color: $color-error;
$message-color: #5a73d8;
