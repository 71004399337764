@use 'src/themes/variables' as *;

$color-depression: #2E5BFF;
$color-anxiety: #8C54FF;
$color-stress: #00C1D4;

// More specific selectors
.dass-container {
  padding: 20px;
  background: white;
  border-radius: 8px;

  .dass-chart-container {
    margin-bottom: 20px;
    padding: 0;

    .chart-title {
      margin-bottom: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eee;
    }

    .empty-state {
      padding: 48px 0;
      background: #fafafa;
      border-radius: 8px;
      margin: 24px 0;
    }

    .severity-legend {
      margin: 16px 0;
      padding: 16px;
      background: #fafafa;
      border-radius: 8px;

      .legend-title {
        margin-bottom: 12px;
        color: #666;
        font-weight: 500;
      }

      .severity-legend-items {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .severity-legend-item {
          display: flex;
          align-items: center;
          gap: 8px;

          .color-box {
            width: 12px;
            height: 12px;
            border-radius: 3px;
          }
        }
      }
    }

    div.chart-legend {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin: 16px 0 24px;
      width: 100%;

      div.legend-item {
        display: inline-flex;
        align-items: center;
        padding: 8px 16px;
        border-radius: 6px;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        min-width: 120px;
        border: 2px solid transparent;
        transition: all 0.2s ease;
        user-select: none;

        &[data-type="depression"] {
          border-color: $color-depression;
          
          .legend-label {
            color: $color-depression;
          }
          
          &:hover {
            background-color: rgba($color-depression, 0.05);
          }
        }

        &[data-type="anxiety"] {
          border-color: $color-anxiety;
          
          .legend-label {
            color: $color-anxiety;
          }
          
          &:hover {
            background-color: rgba($color-anxiety, 0.05);
          }
        }

        &[data-type="stress"] {
          border-color: $color-stress;
          
          .legend-label {
            color: $color-stress;
          }
          
          &:hover {
            background-color: rgba($color-stress, 0.05);
          }
        }

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &.inactive {
          background-color: #f5f5f5;
          opacity: 0.7;
          border-color: #ddd;

          .legend-color {
            opacity: 0.4;
          }

          .legend-label {
            color: #666 !important;
          }

          &:hover {
            transform: none;
            background-color: #f0f0f0;
          }
        }

        span.legend-color {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
          flex-shrink: 0;
        }

        span.legend-label {
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }

    button.severity-button {
      margin-top: 16px;
      padding: 10px 20px;
      border: none;
      border-radius: 6px;
      background: $color-primary;
      color: white;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease;

      &:hover {
        background: darken($color-primary, 10%);
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }

      &:active {
        transform: translateY(0);
      }
    }

    .dass-statistics {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 16px;
      margin-bottom: 24px;

      > div {
        border: none !important;
      }
    }
  }

  .dass-chart-container:last-child {
    margin-bottom: 0;
  }
}
