.HomeworkDetailsHistoryTab {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;

  &__list {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 16px;
    min-height: 0;
  }
}
