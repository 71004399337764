@use 'src/themes/variables' as *;

.Header {
  position: fixed !important;
  width: 100% !important;
  padding-inline: 30px !important;
  background-color: white !important;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  z-index: 998;
  border-bottom: 1px solid #e8e8e8;
  height: auto !important;
  min-height: 64px;

  &__right {
    display: flex;
    align-items: center;
    gap: 27px;

    @media (max-width: 768px) {
      gap: 15px;
    }

    @media (max-width: 576px) {
      gap: 10px;
    }
  }

  @media (max-width: 768px) {
    padding-inline: 15px !important;

    &.hide-mobile {
      display: none !important;
    }
  }

  @media (max-width: 576px) {
    padding-inline: 10px !important;
  }

  &__icon {
    width: 80px;
    text-align: center;
    cursor: pointer;
    padding-top: 12px;

    @media (max-width: 576px) {
      width: 60px;
    }
  }

  &__avatar {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      @media (max-width: 576px) {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.Menu__item {
  height: 40px !important;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 24px;
  &-title {
    margin-left: 6px;
  }
}

.Menu__item {
  &-role {
    padding: 6px 12px;
    &-icon {
      margin-right: 6px;
    }
  }
}

.LogoutModal {
  text-align: center;
}

.ant-dropdown-menu-submenu-arrow {
  display: none;
}
