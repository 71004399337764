@use 'src/themes/variables' as *;

.AllAssignedTasks {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (min-width: 480px) {
      flex-direction: row;
    }

    &-total {
      font-size: 10px;
      padding: 3px 8px;
      border-radius: 6px;
      color: $dark-violet;
      background-color: $light-violet;
    }

    &-action {
      color: $font-color-caption;
      margin-left: auto;

      &-btn {
        &.ant-btn {
          color: #ffffff;

          &.noBorder {
            box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
  

  &__tabs {
    margin-top: 16px;

    .Search {
      margin: 16px 0;
    }

    .AssignedTaskList {
      height: auto;
      max-height: 515px;
      margin-right: -20px;
      padding-right: 20px;
      overflow-y: auto;
      
      @media (max-width: 768px) {
        max-height: 400px;
      }
    }
  }
}
