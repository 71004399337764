@use 'src/themes/variables' as *;

$color-depression: #2E5BFF;
$color-anxiety: #8C54FF;
$color-stress: #00C1D4;

.chart-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 12px 0 24px;
  width: 100%;
  justify-content: center;

  .legend-item {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    min-width: 120px;
    border: 2px solid transparent;
    transition: all 0.2s ease;
    user-select: none;

    &[data-type="depression"] {
      border-color: $color-depression;
      
      .legend-label {
        color: $color-depression;
      }
      
      &:hover {
        background-color: rgba($color-depression, 0.05);
      }
    }

    &[data-type="anxiety"] {
      border-color: $color-anxiety;
      
      .legend-label {
        color: $color-anxiety;
      }
      
      &:hover {
        background-color: rgba($color-anxiety, 0.05);
      }
    }

    &[data-type="stress"] {
      border-color: $color-stress;
      
      .legend-label {
        color: $color-stress;
      }
      
      &:hover {
        background-color: rgba($color-stress, 0.05);
      }
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &.inactive {
      background-color: #f5f5f5;
      opacity: 0.7;
      border-color: #ddd;

      .legend-color {
        opacity: 0.4;
      }

      .legend-label {
        color: #666 !important;
      }

      &:hover {
        transform: none;
        background-color: #f0f0f0;
      }
    }

    .legend-color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
      flex-shrink: 0;
    }

    .legend-label {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }
  }
}
