@use 'src/themes/variables' as *;

.RegisterPage {
  background-color: #f5f5f5;

  .ImgContainer__right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }

  &__container-heading {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__errorMsg {
    margin-bottom: 1rem;
    display: block;
  }

  &__container-endText {
    text-align: center;
    margin-top: 1rem;
  }

  &__container {
    &-heading {
      margin-bottom: 16px;
    }

    &-heading,
    &-endText {
      text-align: center !important;
      font-size: 12px;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $light-green07 !important;
    border-color: $light-green07 !important;
  }
}
