@import './colors';
@import './mixins';

// Font
$font-size-caption: 12px;
$font-size-small: 13px;
$font-size-x-small: 10px;
$font-size-default: 14px;
$font-size-sub: 16px;
$font-size-title: 20px;
$font-size-headline: 24px;
$font-size-heading: 34px;
$font-size-xl: 45px;
$font-size-large: 56px;
$font-size-medium: 20px;
