@use '../../../themes/variables' as *;

.InviteSuccess {
  .ant-modal-content {
    padding: 20px 20px 30px 20px;
    .invite-success-modal {
      width: 100%;

      .button-close {
        height: 48px;
        margin-right: 10px;
        width: 88px;
      }
      .button-profile {
        height: 48px;
        width: 232px;
        font-size: $font-size-default;
        font-family: 'Poppins-400';
      }
      .title {
        text-align: center !important;
        font-size: $font-size-headline;
        font-family: 'Poppins-400';
      }
      .icon {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
      }
    }
  }
}
