@use 'src/themes/variables' as *;

.FilesPage {
  &__wrapper {
    .CommonContent__content {
      padding: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 16px;
    width: 100%;
    max-width: 1020px;
    height: 100%;
    border-radius: 0px 0px 8px 8px;

    @media (max-width: 768px) {
      padding: 12px;
    }
  }

  &__uploadBtn {
    &.ant-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      
      &:hover {
        color: $white;
        background-color: $color-primary;
      }

      @media (max-width: 768px) {
        position: fixed;
        bottom: 24px;
        right: 24px;
        z-index: 100;
        width: auto;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        padding: 6px 16px;
        background: $color-primary;
        color: $white;

        &:hover {
          transform: scale(1.05);
          transition: all 0.2s ease;
        }
      }
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin: 0 0 16px;

    &-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-action {
      color: $font-color-caption;

      &-btn {
        &.ant-btn {
          color: $color-primary;
          width: 36px;
          height: 36px;

          &.noBorder {
            box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .ant-table {
    @media (max-width: 768px) {
      font-size: $font-size-caption;
      
      .ant-table-container {
        -webkit-overflow-scrolling: touch;
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 12px;
      }

      .ant-table-tbody > tr > td {
        vertical-align: middle;
      }

      .ant-table-row {
        cursor: pointer;
        
        &:hover {
          background-color: $bg-gray;
        }
      }

      .ant-table-cell {
        max-width: 200px;
        
        &.ant-table-cell-fix-left,
        &.ant-table-cell-fix-right {
          z-index: 1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $border-default-color;
      border-radius: 3px;
    }

    .ant-table {
      border: none;
      border-radius: 0;
    }
    
    .ant-table-container {
      border: none;
      border-radius: 0;
    }
  }
}
