@use 'src/themes/variables' as *;

.Chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;

  &__wrapper {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__header {
    flex-shrink: 0;
    padding: 12px 16px;
    border-bottom: 1px solid $border-default-color;
    background-color: $white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;

    @media (max-width: 768px) {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
    }

    &.noRoom {
      border-bottom: none;
    }

    &-profile {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1;
      min-width: 0;

      .BaseText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-back {
      display: none;
      color: $font-color-default;
      
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        flex-shrink: 0;
      }

      svg {
        transform: rotate(180deg);
        width: 20px;
        height: 20px;
      }
    }

    &-action {
      color: $font-color-default;
      flex-shrink: 0;
    }

    &-delete {
      color: $color-error;
      padding: 4px 8px;
      width: 100%;
      justify-content: flex-start;

      &:hover {
        color: $color-error;
        background-color: $bg-error;
      }
    }
  }

  &__content {
    flex: 1;
    min-height: 0;
    overflow: auto;
    padding: 24px 16px;
    display: flex;
    flex-direction: column-reverse;
    -webkit-overflow-scrolling: touch;

    @media (max-width: 768px) {
      padding: 16px;
    }

    &.noRoom {
      justify-content: center;
      align-items: center;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $border-default-color;
      border-radius: 3px;
    }
  }

  &__action {
    flex-shrink: 0;
    padding: 12px 16px;
    border-top: 1px solid $border-default-color;
    background-color: $white;
    position: relative;
    z-index: 2;

    @media (max-width: 768px) {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &.readOnly {
      background-color: $bg-gray;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    border-radius: 0;
  }
}
