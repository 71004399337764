@use 'src/themes/variables' as *;

.HomeworkTab {
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__tasks {
    padding: 20px;
    flex: 1;
    
    @media (min-width: 768px) {
      min-width: 50%;
      max-width: 50%;
      padding-right: 20px;
      border-right: 1px solid $border-default-color;
    }
  }

  &__history {
    width: 100%;
    padding: 20px;
    
    @media (min-width: 768px) {
      width: 50%;
    }
  }

  &__removeDescription {
    color: $philippine-gray;
  }
}
