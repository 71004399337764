@use 'src/themes/variables' as *;

.pricing-container {
  background-color: $grey;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 70px 20% 50px 20%;

  .header {
    color: $font-color-default;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 40px;
  }

  .content {
    background-color: white;
    padding: 20px;
    padding-bottom: 60px;
    border-radius: 8px;
    max-width: 796px;
  }

  .description {
    text-align: center;
  }
}
