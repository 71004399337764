@use '../../../../../../themes/colors' as *;

.CancelSubcription {
  &__footer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    justify-content: center;
    &--btnleft {
      margin-right: 10px;
    }
  }
  .ant-modal-content {
    padding: 30px 20px;
  }
  &__reason {
    &--title {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      font-family: 'Poppins-500';
    }
  }
}
