@use 'src/themes/variables' as *;

.ClientsPage {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }

  .CommonContent {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin: 0;
      background: none;
      min-height: 0;
      
      @media (max-width: 768px) {
        padding: 0;
      }
    }

    @media (max-width: 768px) {
      margin: 0;
      padding: 0;
    }
  }

  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;

    @media (max-width: 768px) {
      box-shadow: none;
      border-radius: 0;
      padding: 12px;
    }
  }

  &__header {
    width: 100%;
    margin-bottom: 16px;
    
    .Search {
      width: 100%
    }
  }

  .CommonContent__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    background: #f5f5f5;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  
  .CommonContent__non-flex-content {
    flex: 1;
    padding: 0;
    background: #f5f5f5;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  &__inviteBtn {
    &.ant-btn {
      &:hover {
        color: $white;
        background-color: $color-primary;
      }

      @media (max-width: 768px) {
        position: fixed;
        bottom: 24px;
        right: 24px;
        z-index: 100;
        width: auto;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 24px;
        padding: 6px 16px;
        background: $color-primary;
        color: $white;

        &:hover {
          transform: scale(1.05);
          transition: all 0.2s ease;
        }
      }
    }
  }

  &__dischargeModal {
    &-confirm-text {
      text-align: center !important;
      color: $font-color-caption;
    }
  }

  .ClientsTable {
    flex: 1;
    min-height: 0;

    @media (max-width: 768px) {
      margin: 0;
      width: 100vw;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      
      :global(.ant-table-wrapper) {
        .ant-table {
          .ant-table-container {
            border-radius: 0;
            
            table {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
