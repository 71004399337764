@use 'src/themes/variables' as *;

.ClientsTable {
  &.Table {
    height: 100%;
    width: 100%;
    
    .ant-table-wrapper {
      height: 100%;
      
      .ant-spin-nested-loading {
        height: 100%;
        
        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          
          .ant-table {
            flex: 1;
            min-height: 0;
          }
        }
      }
    }

    .ant-table-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .ant-table-body {
      flex: 1;
      min-height: 0;
      overflow: auto !important;
    }

    @media (max-width: 768px) {
      .ant-table {
        border: none;
        border-radius: 0;
      }
      
      .ant-table-container {
        border: none;
        border-radius: 0;
      }
    }
  }

  &.Table.ant-table-wrapper .ant-table-thead .ant-table-cell {
    font-family: 'Poppins-400';
    font-weight: 500;
    white-space: nowrap;
    background: $white;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &.Table.ant-table-wrapper .ant-table-cell {
    padding: 9px 16px;

    @media (max-width: 768px) {
      padding: 8px 12px;
    }
  }

  &.Table .ant-table-cell .details_container {
    display: flex;
    align-items: center;
    gap: 10px;

    .body1 {
      display: flex;
      align-items: center;
      flex: 1;
      min-width: 0;
      
      @media (max-width: 768px) {
        font-size: $font-size-caption;
      }
    }

    .avatar img {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &.Table .ant-table-filter-column {
    justify-content: normal;

    .ant-table-column-title {
      flex: none;
    }

    .ant-table-filter-trigger {
      margin-inline: 6px -8px;
    }
  }

  &__dischargeBtn {
    &.ant-btn {
      font-family: 'Poppins-400';
      font-size: $font-size-caption;
      border-style: none;
      padding: 6px 16px;
      line-height: 16px;
      white-space: nowrap;
    }
  }

  .ant-table-filter-trigger {
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 54px;
    z-index: 1;
    top: 4px;
    bottom: 0;
  }

  @media (max-width: 768px) {
    .ant-table {
      font-size: $font-size-caption;
    }

    .ant-table-container {
      -webkit-overflow-scrolling: touch;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ant-table-tbody > tr > td {
      vertical-align: middle;
    }

    .ant-table-row {
      cursor: pointer;
      
      &:hover {
        background-color: $bg-gray;
      }
    }

    .ant-table-cell {
      max-width: 200px;
      
      &.ant-table-cell-fix-left,
      &.ant-table-cell-fix-right {
        z-index: 1;
      }
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $border-default-color;
      border-radius: 3px;
    }
  }
}

