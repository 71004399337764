.generated-document-container {
  &__modalFooter {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  &__documentView {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__documentMetadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__metadata {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__documentContent {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 24px;
    max-height: 60vh;
    overflow-y: auto;
  }

  &__h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #1f1f1f;
  }

  &__h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #1f1f1f;
  }

  &__h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #1f1f1f;
  }

  &__paragraph {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 16px;
    color: #262626;
  }

  &__list {
    margin-bottom: 16px;
    padding-left: 24px;
  }

  &__listItem {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 8px;
    color: #262626;
  }

  .ant-table {
    margin-bottom: 24px;
    
    .ant-table-thead > tr > th {
      background-color: #fafafa;
      font-weight: 600;
    }

    .ant-table-row {
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  pre {
    background-color: #f0f0f0;
    padding: 12px;
    border-radius: 4px;
    overflow-x: auto;
    margin-bottom: 16px;
    
    code {
      font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
      font-size: 13px;
      line-height: 1.4;
    }
  }

  code {
    background-color: #f0f0f0;
    padding: 2px 6px;
    border-radius: 4px;
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
    font-size: 13px;
  }

  blockquote {
    border-left: 4px solid #d9d9d9;
    margin: 0 0 16px;
    padding: 8px 16px;
    color: #595959;
    font-style: italic;
  }

  &__viewButton.ant-btn-link {
    padding: 0;
    color: #1890ff;

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }
} 