@use 'src/themes/variables' as *;

.PreviewFilesModal {
  &.ant-modal {
    .ant-modal-content {
      padding: 20px 30px;
    }
  }

  &__carousel {
    background-color: $bg-gray;
    border-radius: 8px;

    &-image {
      object-fit: contain;
    }

    .Image {
      width: 100%;
      height: 100%;
    }

    &.ant-carousel {
      .slick-arrow {
        top: 0;
        height: 100%;
        display: flex !important;
        color: $color-primary;

        &:hover {
          color: $color-primary;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__action {
    margin: 16px auto 0;
    width: 100px;
  }
}
