.HomeworkHistory {
  &__range {
    flex-direction: column;
    gap: 8px;
    
    @media (min-width: 480px) {
      flex-direction: row;
      align-items: center;
    }

    .DatePicker {
      height: 36px;
    }
  }

  &__list {
    height: auto;
    max-height: 580px;
    
    @media (max-width: 768px) {
      max-height: 400px;
    }

    .HomeworkHistoriesByDate {
      &:not(:first-child) {
        margin-top: 18px;
      }
    }
  }
}
