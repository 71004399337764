@use 'src/themes/variables' as *;

.HomeworkList {
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 200px);
  }

  &.has-border {
    padding: 6px;
    border: 1px solid $border-default-color;
    border-radius: 8px;
  }
}
