@use 'src/themes/variables' as *;

.PaymentMethod {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-btnContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $font-color-caption;

      &--btn {
        color: $color-primary;
        border: none;

        &.ant-btn {
          width: 36px;
          height: 36px;
          box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-cardContainer {
      padding: 16px 20px;
      border: 1px solid $border-default-color;
      border-radius: 8px;

      &-item {
        display: flex;
        gap: 6px;

        &-moreOptionBtn {
          border: none;
          margin-left: auto;

          &.ant-btn {
            width: 36px;
            height: 36px;
            box-shadow: none;
            background-color: $bg-input-color;
          }
        }
      }
      &-brand {
        height: 38px;
      }
    }
  }
}
