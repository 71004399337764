@use 'src/themes/variables' as *;

.AssignClientsModal {
  .ant-modal-content {
    padding: 20px !important;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--title {
      margin-top: 16px;
    }

    &-table {
      border-radius: 8px;
      border: 1px solid $border-default-color;

      &--header,
      &--content {
        padding: 6px 11px;
        margin: 0 !important;
        align-items: center;
      }

      &--header {
        color: $color-primary;
        border-bottom: 1px solid $border-default-color;
        background-color: $bg-gray-2;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 10px 16px;
      }

      &--content {
        &--checkbox {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &__error {
    color: $color-error;
    margin-top: 6px;
  }

  &__footer {
    display: flex;
    gap: 10px;
    margin-top: 16px;

    .Button {
      width: 175px;
    }
  }
}
