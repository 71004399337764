.ClientGeneralInfomationForm {
  padding: 20px;

  &__title {
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .FormItem {
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 16px;

    .Button {
      width: 160px;
    }
  }
}
