@use 'src/themes/variables' as *;

.NotificationItem {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;

  &__avatar {
    height: 100%;
    .Avatar.ant-avatar {
      width: 45px;
      height: 45px;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    &-time {
      height: 15px;
      color: #8a8a8a;
    }
  }

  &__dot {
    min-width: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &:hover {
    background-color: $bg-gray;
  }
}
