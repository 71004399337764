@use 'src/themes/variables' as *;

.SingleMessage {
  margin-top: 6px;

  &__timeline {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: $font-color-caption;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  &__text {
    width: fit-content;
    padding: 5px 16px;
    border-radius: 12px;
    background-color: $grey;

    &.own {
      margin-left: 100px;
      background-color: #4FC3F7;
      color: #FFFFFF;
    }

    &.other {
      margin-right: 100px;
    }

    &.highlight-text {
      background-color: #ffdddd; // Light red background for flagged text
      font-weight: bold; // Bold text for flagged text
      text-decoration: underline dotted #dc3545; // Dotted underline with red color for flagged text
      padding: 8px 16px; // Adjusted padding for the flagged text
      margin-bottom: 6px; // Add a margin at the bottom for spacing  
    }

    &.classification {
      background-color: #ffdddd;
    }
  }

  &.own {
    margin-left: auto;
  }

  &__quickFiles {
    margin-bottom: 6px;
  }

  .SingleMessage__partner-details {
    width: 100%;

    &.flag {
      border-radius: 10px;
      background-color: #ffdddd;
      border: 1px solid #ff0000;
      color: #ff0000;
    }
  }

  &__partner {
    display: flex;
    align-items: flex-start;
    gap: 6px;

    &.showAvt {
      margin-top: 10px;
    }

    &.hideAvt {
      .SingleMessage__partner-details {
        margin-left: 38px;
      }
    }
  }
}
