@use 'src/themes/variables' as *;

.PaymentPage {
  &__content {
    max-width: 610px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px !important;
    gap: 16px;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__body {
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &--checkbox {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $light-green07 !important;
        border-color: $light-green07 !important;
      }
    }

    &-cardRow {
      display: flex;
      gap: 16px;
    }

    &-cardElement {
      padding: 10px;
      background-color: $bg-input-color;
      border-radius: 10px;
      width: 100%;
      border: 1px solid $bg-input-color;

      &.invalid {
        border: 1px solid $color-error;
      }
    }

    .show {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }

    .hide {
      opacity: 0;
    }
  }

  &__footer {
    display: flex;
    gap: 10px;

    .Button {
      width: 175px;
    }
  }
}
