@use '../../../themes/colors' as *;

.AlertModal {
  .ant-modal-content {
    padding: 20px 20px 30px !important;
    .discharge-modal {
      width: 100%;

      .button {
        height: 48px;
        width: 160px;
        margin-top: 8px;
      }

      .btn-margin {
        margin-right: 10px;
      }

      .btn-delete-color {
        background-color: $color-error;
      }

      .title {
        text-align: center !important;
        margin-bottom: 8px;
        font-size: 24px;
        font-family: 'Poppins-400';
        line-height: 36px;
      }

      .body {
        text-align: center !important;
        font-weight: 400;
        color: $philippine-gray;
      }

      .icon {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
      }
    }
  }
  &__content {
    color: $philippine-gray;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
  &__icon {
    &--container {
      text-align: center;
    }
  }
}
