.live-transcribe-page {
  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 16px;
      
      &::before {
        border-bottom: none !important;
      }

      .ant-tabs-nav-list {
        height: 40px;
      }
    }

    .ant-tabs-tab {
      background: white !important;
      border: 1px solid #d9d9d9 !important;
      border-radius: 4px !important;
      margin: 0 8px 0 0 !important;
      padding: 6px 16px !important;
      transition: all 0.3s;
      height: 40px;
      display: flex;
      align-items: center;

      .ant-tabs-tab-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 100%;
      }

      &:hover {
        color: #1890ff !important;
        border-color: #1890ff !important;
      }

      &.ant-tabs-tab-active {
        background: white !important;
        border-color: #1890ff !important;

        .ant-tabs-tab-btn {
          color: #1890ff !important;
        }
      }

      &[data-generated="true"] {
        border-color: #722ed1 !important;
        
        .ant-tabs-tab-btn {
          color: #722ed1 !important;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &:hover {
          color: #9254de !important;
          border-color: #9254de !important;
        }

        &.ant-tabs-tab-active {
          border-color: #722ed1 !important;
          
          .ant-tabs-tab-btn {
            color: #722ed1 !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }
  }

  // Add transcript segment styles
  .transcript-segment {
    background: white;
    transition: all 0.2s ease;
    cursor: pointer;
    
    .segment-container {
      position: relative;
    }

    .segment-content {
      display: flex;
      align-items: baseline;
      gap: 8px;
      
      .speaker-label {
        color: #1890ff;
        font-weight: 500;
        white-space: nowrap;
      }
      
      .segment-text {
        color: #333;
        line-height: 1.5;
        flex: 1;
      }

      .segment-pills {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-left: auto;
        flex-shrink: 0;
        opacity: 0;
        transition: opacity 0.2s ease;

        .confidence-pill {
          background: #f6ffed;
          border-color: #52c41a;
          color: #52c41a;
        }

        .time-pill {
          background: #f5f5f5;
          border-color: #d9d9d9;
          color: #666;
        }

        .ant-tag {
          margin: 0;
          padding: 2px 8px;
          border-radius: 12px;
          font-size: 12px;
        }
      }
    }

    &:hover {
      background: #f8f9fa;
      border-color: #e6f4ff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

      .segment-pills {
        opacity: 1;
      }
    }
  }
}

// Add some color variations for different speakers
$speaker-colors: (#1890ff, #52c41a, #722ed1, #fa8c16, #f5222d);

@for $i from 1 through length($speaker-colors) {
  .speaker-tag:nth-of-type(#{length($speaker-colors)}n + #{$i}) {
    background-color: nth($speaker-colors, $i);
    color: white;
  }
}

.raw-line {
  padding: 8px;
  color: #666;
}

.system-message {
  padding: 8px;
  color: #666;
  font-style: italic;
} 