@use 'src/themes/variables' as *;

.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #f5f5f5;

  &.resend {
    min-height: auto;
  }

  .confirm-email-icon {
    margin: 0 auto;
  }

  .info-box {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }

  .more-info-text {
    text-align: center;
  }

  .end-text {
    margin-top: 1rem;
  }
}
