@use 'src/themes/variables' as *;

.UploadModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .ant-form-item-explain-error {
    margin-bottom: 16px;
  }

  .ant-space-item {
    .ant-row {
      margin-top: 0 !important;
    }
  }

  &__body {
    margin-top: 10px;
  }

  &__title {
    font-family: 'Poppins-500';
    text-align: center;
    margin-bottom: 16px;
    font-size: $font-size-title;
  }

  &__caption {
    color: $font-color-caption;
  }

  &__footer {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    .button {
      width: 100%;
    }
  }

  .bt-upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px 0px 16px;
    gap: 6px;
    height: 48px;
    width: '100%';
    border: 1.5px dashed #19bcfe;
    border-radius: 10px;
    color: #19bcfe;
    width: 320px;
  }
}
